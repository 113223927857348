import React from "react";
import classNames from "classnames/bind";
import styles from "./EmailContactGrid.module.scss";

import { Section, Column } from "components/animated";
import Container from "react-bulma-components/lib/components/container";
import Columns from "react-bulma-components/lib/components/columns";
import posed from "react-pose";
import { validateText } from "utils/renderHelpers";
import { animatedContainer, animatedChild } from "utils/commonPoses";
import Waypoint from "react-waypoint";

const cx = classNames.bind(styles);

const AnimatedSection = posed(Section)(animatedContainer);
const AnimatedColumn = posed(Column)(animatedChild);
const AnimatedHeading = posed.h2(animatedChild);
const AnimatedSubHeading = posed.p(animatedChild);

function InfluencerContacts({ primary, items, theme = null, size = null }) {
  let { heading, subheading } = primary;
  let [visible, setVisible] = React.useState(false);
  let handleEnter = React.useCallback(() => {
    setVisible(true);
  }, [setVisible]);

  return (
    <Waypoint
      key={"InfluencerContacts"}
      scrollableAncestor={typeof window === "undefined" ? null : window}
      onEnter={handleEnter}
      topOffset={`20%`}
      bottomOffset={`20%`}
    >
      <AnimatedSection
        className={cx({ section: true, [theme]: true, [size]: true })}
        initialPose="hidden"
        pose={visible ? "visible" : "hidden"}
        stagger={250}
      >
        <Container>
          <Columns mobile>
            <Columns.Column
              mobile={{ size: 11 }}
              tablet={{ size: 6 }}
              desktop={{ size: size === `large` ? 5 : 4 }}
            >
              {validateText(heading) && (
                <AnimatedHeading>{heading.text}</AnimatedHeading>
              )}
              {subheading && validateText(subheading) && (
                <AnimatedSubHeading className={cx({ subheading: true })}>
                  {subheading.text}
                </AnimatedSubHeading>
              )}
            </Columns.Column>
          </Columns>
          <Columns mobile multiline>
            {items.map((item) => {
              let { heading, email } = item;
              let emailLabel = email.url.replace("mailto:", "");
              return (
                <AnimatedColumn
                  mobile={{ size: 12 }}
                  tablet={{ size: 6 }}
                  desktop={{ size: 4 }}
                >
                  <h3 style={{ fontWeight: `bold` }}>{heading.text}</h3>
                  <a
                    title={heading.text}
                    href={email.url}
                    style={{ fontSize: 16 }}
                  >
                    {emailLabel}
                  </a>
                </AnimatedColumn>
              );
            })}
          </Columns>
        </Container>
      </AnimatedSection>
    </Waypoint>
  );
}

export default InfluencerContacts;
