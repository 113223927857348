import React, { PureComponent } from 'react';
import { Section } from 'components/animated';
import Columns from 'react-bulma-components/lib/components/columns';
import Container from 'react-bulma-components/lib/components/container';
import { validateText, validateHtml } from 'utils/renderHelpers';
import { renderFluidImage } from 'utils/imageHelpers';
import posed from 'react-pose';
import {
  animatedChild,
  animatedReveal,
  animatedContainer
} from 'utils/commonPoses';
import Waypoint from 'react-waypoint';
import uid from 'utils/uid';

import classNames from 'classnames/bind';
import styles from './FeatureContentWithImage.module.scss';

const cx = classNames.bind(styles);
const AnimatedSection = posed(Section)(animatedContainer);
const AnimatedContent = posed.div(animatedContainer);
const AnimatedLargeHeading = posed.h3(animatedChild);
const AnimatedSmallHeading = posed.h4(animatedChild);
const AnimatedTagline = posed.aside(animatedChild);
const AnimatedParagraph = posed.article(animatedChild);
const AnimatedImage = posed.figure(animatedChild);
const ImageReveal = posed.figure(animatedReveal);

export default class FeatureContentWithImage extends PureComponent {
  state = { visible: false };

  constructor(props) {
    super(props);
    this.id = uid();
  }

  onEnter = () => {
    this.setState({ visible: true });
  };

  render() {
    const { primary, items, large = false } = this.props;
    const isFade =
      primary.image_animation && primary.image_animation === 'fade';
    const isRtl = primary.orientation === 'RTL';

    return (
      <Waypoint
        scrollableAncestor={typeof window === 'undefined' ? null : window}
        onEnter={this.onEnter}
        topOffset={`20%`}
        bottomOffset={`50%`}
      >
        <AnimatedSection
          initialPose="hidden"
          direction={isRtl ? -1 : 1}
          pose={this.state.visible ? 'visible' : 'hidden'}
          className={`is-theme-${primary.theme} ${cx({
            section: true,
            [primary.theme]: true,
            [primary.orientation.toLowerCase()]: true
          })}`}
        >
          <Container>
            <Columns mobile multiline>
              <Columns.Column
                className={`is-order-2-mobile is-flex-tablet ${
                  isRtl ? `is-order-2-tablet` : `is-order-1-tablet`
                } ${cx({ column: true })}`}
                mobile={{ size: 12 }}
                tablet={{ size: 6 }}
              >
                <AnimatedContent
                  initialPose="hidden"
                  pose={this.state.visible ? 'visible' : 'hidden'}
                  direction={1}
                  withParent={false}
                  className={cx({
                    content: true,
                    [primary.theme]: true,
                    [primary.orientation.toLowerCase()]: true
                  })}
                >
                  {validateText(primary.large_heading) && (
                    <AnimatedLargeHeading className={styles.largeHeading}>
                      {primary.large_heading.text}
                    </AnimatedLargeHeading>
                  )}
                  {validateText(primary.small_heading) && (
                    <AnimatedSmallHeading className={styles.smallHeading}>
                      {primary.small_heading.text}
                    </AnimatedSmallHeading>
                  )}
                  {validateHtml(primary.tagline) && (
                    <AnimatedTagline
                      className={cx({ tagline: true, [primary.theme]: true })}
                      dangerouslySetInnerHTML={{ __html: primary.tagline.html }}
                    />
                  )}
                  {validateHtml(primary.paragraph) && (
                    <AnimatedParagraph
                      className={cx({
                        paragraph: true,
                        [primary.theme]: true,
                        large
                      })}
                      dangerouslySetInnerHTML={{
                        __html: primary.paragraph.html
                      }}
                    />
                  )}
                </AnimatedContent>
              </Columns.Column>
              <Columns.Column
                mobile={{ size: 12 }}
                tablet={{ size: 6 }}
                className={`is-flex-tablet is-order-1-mobile ${
                  isRtl ? `is-order-1-tablet` : `is-order-2-tablet`
                } ${cx({ imageContainer: true })}`}
              >
                {items.map((item, idx) => {
                  return isFade ? (
                    <AnimatedImage
                      key={`featureContentWithImage-${this.id}-${idx}`}
                      className={styles.image}
                    >
                      {renderFluidImage(item.image)}
                    </AnimatedImage>
                  ) : (
                    <figure
                      key={`featureContentWithImage-${this.id}-${idx}`}
                      className={styles.image}
                    >
                      {renderFluidImage(item.image)}
                      <ImageReveal
                        className={cx({
                          [primary.theme]: true,
                          imageReveal: true
                        })}
                      />
                    </figure>
                  );
                })}
              </Columns.Column>
            </Columns>
          </Container>
        </AnimatedSection>
      </Waypoint>
    );
  }
}
