import React, { Component } from "react";
// Components
import Helmet from "react-helmet";

import Hero from "slices/Hero";
import FeatureContentWithImage from "slices/FeatureContentWithImage";
import FeatureContent from "slices/FeatureContent";
import FullWidthImage from "slices/FullWidthImage";
import EmailContactGrid from "components/EmailContactGrid";
import getPageMeta from "utils/getPageMeta";

// graphql
import { graphql } from "gatsby";

class CareersPage extends Component {
  state = {
    page: this.props.data.page,
  };

  renderSlice = (slice) => {
    if (!slice) {
      return null;
    }

    let Cmp;
    switch (slice.slice_type) {
      case "hero":
        slice.large = true;
        slice.compressed = false;
        slice.subheadingAsParagraph = true;
        Cmp = Hero;
        break;
      case "feature_content":
        Cmp = FeatureContent;
        break;
      case "feature_content_with_image":
        Cmp = FeatureContentWithImage;
        break;
      case "internship_contacts":
        slice.theme = "red";
        slice.size = "large";
        Cmp = EmailContactGrid;
        break;
      case "full_width_image":
        Cmp = FullWidthImage;
        break;
      default:
        Cmp = null;
        break;
    }
    if (!Cmp) {
      return null;
    }
    return <Cmp {...slice} key={slice.id} />;
  };

  render() {
    // Set the document object
    const { page } = this.state;
    const { title, body } = page.data;
    return (
      <React.Fragment>
        <Helmet
          title={`${title.text} | Citizen Relations`}
          meta={getPageMeta(
            this.props.pageContext.globals.data,
            this.props.data.page.data,
            this.props.location
          )}
        />
        {body.map((slice) => {
          return this.renderSlice(slice);
        })}
      </React.Fragment>
    );
  }
}

export const query = graphql`
  query($uid: String!) {
    page: prismicCareersPage(uid: { eq: $uid }) {
      uid
      data {
        seo_description {
          text
        }
        seo_keywords {
          text
        }
        seo_image {
          twitter {
            url
            dimensions {
              width
              height
            }
          }
          facebook {
            url
            dimensions {
              width
              height
            }
          }
        }
        seo_image_alt {
          text
        }
        title {
          text
        }
        body {
          ... on PrismicCareersPageBodyHero {
            id
            slice_type
            primary {
              theme
              heading {
                text
                html
              }
              subheading {
                text
                html
              }
              paragraph {
                html
              }
              image {
                url
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicCareersPageBodyInternshipContacts {
            slice_type
            primary {
              heading {
                html
                text
              }
              subheading {
                html
                text
              }
            }
            items {
              heading {
                html
                text
              }
              email {
                link_type
                url
                target
              }
            }
          }
          ... on PrismicCareersPageBodyFeatureContent {
            id
            slice_type
            primary {
              theme
              tagline {
                text
                html
              }
              paragraph {
                text
                html
              }
              link_label
              link {
                url
                raw {
                  link_type
                }
              }
              link_2_label
              link_2 {
                url
                raw {
                  link_type
                }
              }
            }
          }
          ... on PrismicCareersPageBodyFeatureContentWithImage {
            id
            slice_type
            primary {
              theme
              orientation
              image_animation
              small_heading {
                text
              }
              tagline {
                text
                html
              }
              paragraph {
                text
                html
              }
            }
            items {
              image {
                url
              }
            }
          }
          ... on PrismicCareersPageBodyFullWidthImage {
            id
            slice_type
            primary {
              theme
              image {
                url
                dimensions {
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default CareersPage;
