import { PrismicLink, validateHtml, validateText } from "utils/renderHelpers";
import React, { PureComponent } from "react";
import { animatedChild, animatedContainer } from "utils/commonPoses";

import { CgArrowLongRight } from "react-icons/cg";
import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";
import { Section } from "components/animated";
import Waypoint from "react-waypoint";
import classNames from "classnames/bind";
import posed from "react-pose";
import styles from "./FeatureContent.module.scss";

const cx = classNames.bind(styles);

const AnimatedSection = posed(Section)(animatedContainer);
const AnimatedSmallHeading = posed.h4(animatedChild);
const AnimatedTagline = posed.aside(animatedChild);
const AnimatedParagraph = posed.article(animatedChild);
const AnimatedLink = posed(PrismicLink)(animatedChild);

export default class FeatureContent extends PureComponent {
  state = { visible: false };

  onEnter = () => {
    this.setState({ visible: true });
  };

  render() {
    const { primary, large = false } = this.props;
    let {
      theme,
      layout,
      small_heading,
      tagline,
      paragraph,
      link_label,
      link,
      link_2,
      link_2_label,
    } = primary;
    const hasTagline = validateHtml(tagline);
    const hasParagraph = validateHtml(paragraph);
    const taglineLeft = layout ? layout.indexOf("tagline") === 0 : true;

    return (
      <Waypoint
        scrollableAncestor={typeof window === "undefined" ? null : window}
        onEnter={this.onEnter}
        topOffset={`30%`}
        bottomOffset={`30%`}
      >
        <AnimatedSection
          className={`is-theme-${theme} ${cx({
            featureContent: true,
            [theme]: true,
          })}`}
          initialPose="hidden"
          pose={this.state.visible ? "visible" : "hidden"}
          style={{ paddingBottom: !hasParagraph && !link ? 0 : null }}
        >
          <Container>
            <Columns mobile multiline>
              {validateText(small_heading) && (
                <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
                  <AnimatedSmallHeading className={styles.smallHeading}>
                    {small_heading.text}
                  </AnimatedSmallHeading>
                </Columns.Column>
              )}
              {hasTagline && (
                <Columns.Column
                  mobile={{ size: 12 }}
                  tablet={{ size: taglineLeft ? 5 : 12 }}
                >
                  <AnimatedTagline
                    className={cx({ tagline: true, [theme]: true })}
                    dangerouslySetInnerHTML={{ __html: tagline.html }}
                  />
                </Columns.Column>
              )}
              {(hasParagraph || link) && (
                <Columns.Column
                  mobile={{ size: 12 }}
                  tablet={{
                    size: hasTagline ? (taglineLeft ? 6 : 12) : 12,
                    offset: hasTagline ? (taglineLeft ? 1 : null) : null,
                  }}
                >
                  {hasParagraph && (
                    <AnimatedParagraph
                      className={cx({
                        paragraph: true,
                        [theme]: true,
                        columns: hasTagline === false || taglineLeft === false,
                        large,
                      })}
                      dangerouslySetInnerHTML={{ __html: paragraph.html }}
                    />
                  )}
                  {link && (
                    <AnimatedLink
                      className={styles.button}
                      link={link}
                      label={link_label}
                    >
                      <span>{link_label}</span>
                      <i
                        className="icon"
                        style={{ fontSize: 28, paddingLeft: `1rem` }}
                      >
                        <CgArrowLongRight />
                      </i>
                    </AnimatedLink>
                  )}

                  {link_2 && (
                    <React.Fragment>
                      <br />
                      <AnimatedLink
                        className={styles.button}
                        link={link_2}
                        label={link_2_label}
                      >
                        <span>{link_2_label}</span>
                        <i
                          className="icon"
                          style={{ fontSize: 28, paddingLeft: `1rem` }}
                        >
                          <CgArrowLongRight />
                        </i>
                      </AnimatedLink>
                    </React.Fragment>
                  )}
                </Columns.Column>
              )}
            </Columns>
          </Container>
        </AnimatedSection>
      </Waypoint>
    );
  }
}
