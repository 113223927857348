import React, { PureComponent } from 'react';
import { Section } from 'components/animated';
import Container from 'react-bulma-components/lib/components/container';
import classNames from 'classnames/bind';
import styles from './FullWidthImage.module.scss';
import { renderFluidImage } from 'utils/imageHelpers';

import {
  animatedReveal,
  animatedContainer,
  animatedChild
} from 'utils/commonPoses';
import posed from 'react-pose';
import Waypoint from 'react-waypoint';
import { validateText } from 'utils/renderHelpers';

const cx = classNames.bind(styles);

const AnimatedSection = posed(Section)(animatedContainer);
const AnimatedHeading = posed.h2(animatedChild);
const ImageReveal = posed.figure(animatedReveal);

export default class FullWidthImage extends PureComponent {
  state = { visible: false };
  onEnter = () => {
    this.setState({ visible: true });
  };
  render() {
    const { primary } = this.props;
    let { image, theme, heading } = primary;
    return (
      <Waypoint
        scrollableAncestor={typeof window === 'undefined' ? null : window}
        onEnter={this.onEnter}
        topOffset={`30%`}
        bottomOffset={`30%`}
      >
        <AnimatedSection
          initialPose="hidden"
          pose={this.state.visible ? 'visible' : 'hidden'}
          className={`is-theme-${theme} ${cx({
            section: true,
            [theme]: true
          })}`}
        >
          <Container>
            {validateText(heading) && (
              <AnimatedHeading>{heading.text}</AnimatedHeading>
            )}
            <figure>{renderFluidImage(image)}</figure>
            <ImageReveal className={cx({ imageReveal: true, [theme]: true })} />
          </Container>
        </AnimatedSection>
      </Waypoint>
    );
  }
}
